import React from 'react'
import styled from 'styled-components'

import Header from '../components/Header/Header'

const HeroWrapper = styled.section`
  position: relative;
  z-index: 3;
  height: 200px;
  background: var(--color-gray-100);
  @media ${p => p.theme.QUERIES.tabletsAndBigger} {
    height: 400px;
  }
`

const HomepageLayout: React.FC = ({ children }) => (
  <>
    <HeroWrapper>
      <Header />
    </HeroWrapper>
    {children}
  </>
)

export default HomepageLayout
